import classnames from 'classnames'
import React, { FC, MouseEvent } from 'react'

import { spaceCalc } from '../../theme'
import { Spinner } from '../Spinner'
import { TextMedium } from '../Typography'

export interface ToggleProps {
  checked?: boolean
  className?: string
  disabled?: boolean
  isLoading?: boolean
  label: string | JSX.Element
  onClick: (e: MouseEvent) => void
}

export const Toggle: FC<ToggleProps> = ({
  checked,
  className,
  disabled,
  isLoading,
  label,
  onClick,
}) => {
  return (
    <label role="switch" className={classnames('e-toggle', className)}>
      <TextMedium style={{ marginRight: isLoading ? spaceCalc(1) : 0 }}>
        {label}
      </TextMedium>
      {isLoading ? (
        <Spinner />
      ) : (
        <input
          className="e-toggle__input"
          data-testid="Toggle"
          type="checkbox"
          onClick={(e: MouseEvent) => (!disabled ? onClick(e) : null)}
          disabled={disabled}
          checked={checked}
          readOnly
        />
      )}
    </label>
  )
}
