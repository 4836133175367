import {
  documentToReactComponents,
  Options,
} from '@contentful/rich-text-react-renderer'
import { BLOCKS, INLINES, Document } from '@contentful/rich-text-types'
import classnames from 'classnames'
import { isEmpty, cond, equals, always, T } from 'ramda'
import React, { ReactNode, StyleHTMLAttributes } from 'react'

import { IconName } from '@elvia/ui/src/components/ElvisIcon'
import Arcgis from '@elvia/poweroutagemap/src/index'
import { SalesforceLinkButton } from '@elvia/salesforce-chat/src/SalesforceLinkButton'
import { ActionBlock } from '@elvia/ui/src/components/ActionBlock'
import { Download } from '@elvia/ui/src/components/Download'
import { ElvisIcon } from '@elvia/ui/src/components/ElvisIcon'
import { FaqBlock } from '@elvia/ui/src/components/FaqBlock'
import { Image } from '@elvia/ui/src/components/Image'
import { InfoBox } from '@elvia/ui/src/components/InfoBox'
import { InfoCard } from '@elvia/ui/src/components/InfoCard'
import { Link, LinkType } from '@elvia/ui/src/components/Link'
import { LinkBlock } from '@elvia/ui/src/components/LinkBlock'
import { LinkImageBlock } from '@elvia/ui/src/components/LinkImageBlock'
import { List } from '@elvia/ui/src/components/List'
import { Placeholder } from '@elvia/ui/src/components/Placeholder'
import { QuoteBlock } from '@elvia/ui/src/components/QuoteBlock'
import {
  TextBody,
  TextImage,
  TitleMedium,
  TitleSmall,
  TypographyTag,
  TypographyType,
} from '@elvia/ui/src/components/Typography'
import * as Icons from '@elvia/icons'

import { EmbeddedVideoWrapper } from './EmbeddedVideoWrapper/EmbeddedVideoWrapper'
import { FaqWrapper } from './FaqWrapper/FaqWrapper'
import { LinkCollectionSection } from './LinkCollectionSection/LinkCollectionSection'
import { Login } from './Login/Login'
import { RelatedContentWrapper } from './RelatedContentWrapper/RelatedContentWrapper'
import { relatedContentWrapperWithPadding } from './RelatedContentWrapper/RelatedContentWrapper.module.scss'
import { Table } from './Table'
import { GobiStory } from '@elvia/ui/src/components/GobiStory'
import * as css from './richTextRender.module.scss'
import { RenderRichTextOptions } from '@elvia/terms/src/renderRichTextTypes'
import JobylonContainer from '@elvia/jobylon/src/JobylonContainer'
import Abbrevation from './abbrevation'
import { useLocalization } from '@elvia/localization/src/useLocalization'
import { useTerms } from '@elvia/terms'
import { Popover } from '@elvia/ui/src/components/Popover'
import { Button, ButtonType } from '@elvia/ui/src/components/Button'

/* eslint-disable @typescript-eslint/no-explicit-any */

enum ComponentTypes {
  actionBlock = 'actionBlock',
  alias = 'alias',
  article = 'article',
  categoryPage = 'categoryPage',
  EmbeddedVideo = 'embeddedVideo',
  expandableBlock = 'expandableBlock',
  factBlock = 'factBlock',
  faqBlock = 'faqBlock',
  faqCollection = 'faqCollection',
  form = 'form',
  frontpage = 'frontpage',
  icon = 'icon',
  imageWithFocalPoint = 'imageWithFocalPoint',
  infoBox = 'infoBlock',
  interactiveComponent = 'interactiveComponent',
  linkBlock = 'link',
  linkCollectionBlock = 'linkCollectionBlock',
  mainCategory = 'mainCategory',
  relatedContent = 'relatedContent',
  infoCard = 'infoCard',
  table = 'table',
  gobiStory = 'gobiStory',
  jobylon = 'jobylon',
}

export interface Icon {
  icon: { nb: IconName }
  id: string
  typeName: string
}

interface RichTextNode<T> {
  data: T
  content: []
  nodeType: string
}

const getIcon = (iconNode: RichTextNode<Icon>) => {
  const { icon } = iconNode.data
  if (typeof icon.nb !== 'string' || !icon.nb)
    return <Placeholder title={'no icon'} />

  const isElviaIcon = icon.nb.startsWith('Elvia')
  const isHddIcon = typeof (Icons as Icons.Icons)[icon.nb] !== 'undefined'

  if (isElviaIcon) return <ElvisIcon iconName={icon.nb as IconName} />

  if (isHddIcon)
    return (
      (Icons as Icons.Icons)[icon.nb] as (
        attr?: Icons.ISVG | undefined
      ) => React.ReactNode
    )()

  return (
    <span>
      <code style={{ backgroundColor: '#b66dff' }}>[{icon.nb}]</code>
    </span>
  )
}

export const RenderRichText = (
  innhold: Document,
  {
    locale: nodeLocale = 'nb',
    topLevelHeader = 2,
    fancyTitleMode = false,
    lightText = false,
    paragraphType = TypographyType.TEXT_BODY,
    header1Type = TypographyType.TITLE_MEDIUM,
    header2Type = TypographyType.TITLE_SMALL,
    linkType,
    openLinkInNewTab = false,
    centered = false,
    videoOptions,
    replace,
  }: RenderRichTextOptions = {}
): ReactNode => {
  const { getLocalizedValue } = useLocalization()
  const { terms } = useTerms()
  if (!innhold) return null

  const options: Options = {
    renderNode: {
      [INLINES.HYPERLINK]: function renderHyperlink(node, children) {
        return (
          <Link
            href={node.data.uri}
            type={
              linkType || node.data.uri.startsWith('.')
                ? LinkType.Internal
                : LinkType.External
            }
            target={openLinkInNewTab ? '_blank' : '_self'}
          >
            {children}
          </Link>
        )
      },
      [INLINES.ENTRY_HYPERLINK]: function renderEntryHyperlink(node, children) {
        return (
          <>
            {getLocalizedValue(node.data.url, true) ? (
              <Link
                href={getLocalizedValue(node.data.url)}
                target={openLinkInNewTab ? '_blank' : '_self'}
                type={linkType || LinkType.Internal}
              >
                {children}
              </Link>
            ) : (
              <div className={css.inlinePopover}>
                <Popover
                  verticalPosition="bottom"
                  horizontalPosition="left"
                  hasCloseButton
                  trigger={
                    <Button asType={ButtonType.InlineLink}>{children}</Button>
                  }
                  content={
                    <div>
                      <div>{terms.noEnglishTranslationLink}</div>
                      <div>
                        <Link
                          href={getLocalizedValue(node.data.url)}
                          target={openLinkInNewTab ? '_blank' : '_self'}
                          type={linkType || LinkType.Internal}
                        >
                          {children}
                        </Link>
                      </div>
                    </div>
                  }
                />
              </div>
            )}
          </>
        )
      },
      [INLINES.ASSET_HYPERLINK]: function renderAssetHyperlink(node, children) {
        return (
          <Link href={node.data.url} type={linkType || LinkType.External}>
            {children}
          </Link>
        )
      },
      [BLOCKS.QUOTE]: function renderQuote(_, children) {
        return <QuoteBlock>{children}</QuoteBlock>
      },
      [BLOCKS.OL_LIST]: function renderOrderedList(_, children) {
        return <List ordered>{children}</List>
      },
      [BLOCKS.UL_LIST]: function renderUnorderedList(_, children) {
        return <List>{children}</List>
      },
      [BLOCKS.PARAGRAPH]: function renderParagraph(_, children) {
        return (
          <TextBody centered={centered} type={paragraphType}>
            {children}
          </TextBody>
        )
      },
      [BLOCKS.HEADING_1]: function renderHeading1(_, children) {
        return (
          <TitleMedium
            tag={
              TypographyTag[
                ('H' + topLevelHeader) as
                  | 'H1'
                  | 'H2'
                  | 'H3'
                  | 'H4'
                  | 'H5'
                  | 'H6'
              ]
            }
            type={header1Type}
            outline={fancyTitleMode}
            light={lightText}
            coloredDots={fancyTitleMode}
            centered={centered}
          >
            {children}
          </TitleMedium>
        )
      },
      [BLOCKS.HEADING_2]: function renderHeading2(_, children) {
        return (
          <TitleSmall
            tag={
              TypographyTag[
                ('H' + (topLevelHeader + 1)) as 'H2' | 'H3' | 'H4' | 'H5' | 'H6'
              ]
            }
            type={header2Type}
            outline={fancyTitleMode}
            light={lightText}
            coloredDots={fancyTitleMode}
            centered={centered}
          >
            {children}
          </TitleSmall>
        )
      },
      [BLOCKS.EMBEDDED_ENTRY]: function renderEmbeddedEntry(
        node: any,
        children
      ): ReactNode {
        const contentType = node.data.typeName

        // check for drafts
        if (isEmpty(node)) {
          return (
            <Placeholder
              title={`Embedded Entry (empty): ${contentType}`}
              style={
                { marginBottom: '1em' } as StyleHTMLAttributes<HTMLDivElement>
              }
            />
          )
        }

        const imageField = node.image || node.illustration
        const image = imageField?.[nodeLocale]?.node?.file?.[nodeLocale]

        switch (contentType) {
          case ComponentTypes.actionBlock: {
            return (
              <>
                {node?.data && (
                  <ActionBlock
                    status={node.data.status}
                    icon={node.data.icon}
                    url={node.data.url}
                    linkText={getLocalizedValue(node.data.linkText)}
                  >
                    {RenderRichText(getLocalizedValue(node.data.body))}
                  </ActionBlock>
                )}
              </>
            )
          }

          case ComponentTypes.expandableBlock:
            return (
              <>
                {node?.data && (
                  <div className={css.faqBlockContainer}>
                    <FaqBlock
                      articleStyle
                      title={getLocalizedValue(node.data.title)}
                    >
                      {RenderRichText(getLocalizedValue(node.data.body))}
                    </FaqBlock>
                  </div>
                )}
              </>
            )

          case ComponentTypes.infoBox:
            return (
              <InfoBox
                title={getLocalizedValue(node.title)}
                content={children}
                icon={getIcon(node)}
                type={node.type}
              />
            )

          case ComponentTypes.linkBlock:
            return image ? (
              <div className={css.linkImageBlockContainer}>
                <LinkImageBlock
                  title={getLocalizedValue(node.title)}
                  smallTitle={getLocalizedValue(node.description)}
                  src={image.fluid}
                ></LinkImageBlock>
              </div>
            ) : (
              <LinkBlock
                title={getLocalizedValue(node.data.title)}
                icon={node.data.icon}
                href={
                  node.data.asset
                    ? node.data.asset.fields.nb.file.url.nb
                    : node.data.url.nb
                }
                target={node.data.newTab ? '_blank' : '_self'}
                rel={node.data.newTab ? 'noopener noreferrer' : ''}
              >
                {(getLocalizedValue(
                  node.data.description
                ) as React.ReactNode) && (
                  <TextBody>
                    {getLocalizedValue(node.data.description)}
                  </TextBody>
                )}
              </LinkBlock>
            )

          case ComponentTypes.article:
          case ComponentTypes.alias:
          case ComponentTypes.form:
          case ComponentTypes.categoryPage:
          case ComponentTypes.mainCategory:
          case ComponentTypes.frontpage:
            return (
              <RelatedContentWrapper
                className={relatedContentWrapperWithPadding}
                relatedContent={[node.data]}
                largeViewType
              />
            )

          case ComponentTypes.interactiveComponent: {
            const { type } = node.data

            const componentToRender = cond([
              [equals('StrømbruddskartArcgis'), always(<Arcgis />)],
              [equals('StrømbruddskartV2'), always(<Arcgis />)],
              [equals('Logg inn knapper'), always(<Login />)],
              [
                equals('Åpne kundeservice-chat-lenke'),
                always(<SalesforceLinkButton />),
              ],
              [
                T,
                data =>
                  always(
                    <div>
                      React component support missing for type: {data as string}
                    </div>
                  ) as any,
              ],
            ])

            return componentToRender(type) as ReactNode
          }

          case ComponentTypes.faqCollection:
            return <FaqWrapper faqBlocks={node.faqBlocks} />

          case ComponentTypes.relatedContent:
            return (
              <RelatedContentWrapper
                showIcons={node.showIcons}
                relatedContent={[node.data]}
                roundImage
              />
            )

          case ComponentTypes.infoCard:
            return (
              <InfoCard
                body={RenderRichText(getLocalizedValue(node.data.body), {
                  paragraphType: TypographyType.TEXT_LARGE,
                })}
                icon={node.data.icon}
                image={node.data.image}
                linkText={getLocalizedValue(node.data.linkText)}
                title={getLocalizedValue(node.data.title)}
                url={getLocalizedValue(node.data.url)}
              />
            )

          case ComponentTypes.faqBlock:
            return (
              <div className={css.faqBlockContainer}>
                <FaqBlock articleStyle title={node.title}>
                  {children}
                </FaqBlock>
              </div>
            )

          case ComponentTypes.icon:
            return getIcon(node)

          case ComponentTypes.linkCollectionBlock:
            return (
              <LinkCollectionSection
                className={css.linkCollection}
                collection={node.data.references}
              />
            )

          case ComponentTypes.table: {
            return (
              <Table data={node.data.data.tableData} isLimitedWidth={true} />
            )
          }

          case ComponentTypes.EmbeddedVideo: {
            return (
              <div
                className={classnames(css.embeddedVideoContainer, {
                  [css.videoFullWidth]: videoOptions?.fullWidth,
                  [css.videoHideDescription]: videoOptions?.hideDescription,
                })}
              >
                <EmbeddedVideoWrapper
                  title={node.data.title}
                  src={node.data.src}
                  description={node.data.description}
                  fullWidth={videoOptions?.fullWidth}
                />
              </div>
            )
          }

          case ComponentTypes.gobiStory: {
            return (
              <GobiStory
                storyIds={node.data.storyId
                  .split(',')
                  .map((id: string) => id.trim())}
              />
            )
          }

          case ComponentTypes.jobylon: {
            return <JobylonContainer jobylonUrl={node.data.url} />
          }

          case ComponentTypes.imageWithFocalPoint: {
            const { data } = node
            return (
              <div className={css.styledImageContainer}>
                <Image
                  {...data.imgProps}
                  alt={getLocalizedValue(data.imgProps.alt)}
                >
                  {getLocalizedValue(data.description) && (
                    <TextImage style={{ padding: '1em 0', color: '#515151' }}>
                      {RenderRichText(getLocalizedValue(data.description))}
                    </TextImage>
                  )}
                </Image>
              </div>
            )
          }

          default: {
            if (node?.data?.data?.tableData) {
              return (
                <Table data={node.data.data.tableData} isLimitedWidth={true} />
              )
            }
            return (
              <Placeholder
                title={`Embedded Entry: ${node.data.typeName}`}
                showDetails
                data={node}
                style={
                  { marginBottom: '1em' } as StyleHTMLAttributes<HTMLDivElement>
                }
              />
            )
          }
        }
      },
      [BLOCKS.EMBEDDED_ASSET]: function renderEmbeddedAsset(node): ReactNode {
        const entry = node.data.target
        if (!entry.fields) {
          return (
            <Placeholder
              title={`Embedded Asset: ${node.nodeType}`}
              style={
                { marginBottom: '1em' } as StyleHTMLAttributes<HTMLDivElement>
              }
            />
          )
        }
        const file = entry.fields.file
        const text = getLocalizedValue<string>(entry.fields.title)
        const url = file.nb.url
        if (
          file.nb.contentType === 'image/png' ||
          file.nb.contentType === 'image/jpeg'
        ) {
          return (
            <div className={css.styledImageContainer}>
              <Image src={url} alt={text} />
            </div>
          )
        }

        return (
          <div className={css.downloadContainer}>
            <Download url={url} text={text} />
          </div>
        )
      },
      [INLINES.EMBEDDED_ENTRY]: function renderInlineEntry(node): ReactNode {
        const { sys } = node.data
        const type = node.data.typeName

        if (!type) {
          // eslint-disable-next-line no-console
          console.warn(
            'The embedded entry is missing data. Check contentful if it has been published and all fields are correct.',
            node.data
          )
          return (
            <span
              className={classnames(css.inlineWidget, css.inlineWidgetError)}
            >
              <a
                href={`https://app.contentful.com/spaces/yxdcwp9ca2jd/entries/${sys?.id.slice(
                  1
                )}`}
              >
                Blokken er ikke tilgjengelig
              </a>
            </span>
          )
        }
        switch (type) {
          case 'link': {
            return (
              <Link
                href={
                  node.data.asset
                    ? node.data.asset.fields.file.url
                    : node.data.url
                }
                type={LinkType.External}
                target={node.data.newTab ? '_blank' : '_self'}
                rel={node.data.newTab ? 'noopener noreferrer' : ''}
              >
                {node.data.title}
              </Link>
            )
          }
          case 'term':
            return <span className={css.inlineWidget}>{type}</span>
          case 'abbreviation':
            return (
              <Abbrevation
                text={node.data.explanation}
                title={node.data.abbreviation}
              />
            )
          case 'icon':
            return getIcon(node as RichTextNode<Icon>)
          case ComponentTypes.interactiveComponent: {
            const { type } = node.data

            const componentToRender = cond([
              [
                equals('Åpne kundeservice-chat-lenke'),
                always(<SalesforceLinkButton />),
              ],
              [
                T,
                data =>
                  always(
                    <div>
                      Inline React component support missing for type:{' '}
                      {data as ReactNode}
                    </div>
                  ) as unknown as any,
              ],
            ]) as (value: any) => ReactNode

            return componentToRender(type)
          }

          default:
            return <span className={css.inlineWidget}>{type}</span>
        }
      },
    },
    renderText: text => {
      if (replace) {
        replace.forEach((str, index) => {
          if (text.includes('$' + index)) {
            text = text.replace('$' + index, str)
          }
        })
      }
      return [
        text
          .split('\n')
          .reduce(
            (acc, text, i) => [...acc, i > 0 && <br key={i} />, text],
            [] as ReactNode[]
          ), // replace \n with <br />
      ]
    },
  }

  return documentToReactComponents(innhold, options)
}
